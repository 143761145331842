import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { Toast } from '../common/toast';
import { LocalStorage } from '../common/local-storage';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-contactless-payment',
  templateUrl: './contactless-payment.component.html',
  styleUrls: ['./contactless-payment.component.css'],
})
export class ContactlessPaymentComponent implements OnInit {
  applicationId: string | null = null;
  referenceNumber: string | null = null;
  status: number | null = null;
  esbResponseData: any = {};
  oldApplicationDetails: any = {};
  applicationDetails: any = {};
  private loggedUserId: string = this.constants.BLANK;
  paymentStatus: number = 0;
  private encryptedApplicantId: string = this.constants.BLANK;
  private applicationViewForm: HTMLFormElement;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpUtils: HttpUtils,
    public constants: Constants,
    private loaderService: LoaderService,
    public utils: Utils,
    public gui: GUI,
    private toast: Toast,
    private localStorage: LocalStorage
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.applicationId = params['applicationId'] || null;
      this.referenceNumber = params['referenceNumber'] || null;
      this.status = params['status'] || null;
      this.loggedUserId = this.localStorage.getLoggedUserId();
    });
  }
  ngOnInit(): void {
    this.esbNotifyEligbility();
    this.checkApplicationFromEsb();
    // this.applicationViewForm = document.forms['applicationViewForm'];
  }

  private checkApplicationFromEsb(): void {
    // this.loaderService.startLoader();

    this.httpUtils
      .fetchApplicationFromEsb(
        this.applicationId,
        this.referenceNumber,
        this.status
      )
      .then((serverResponse) => {
        if (serverResponse) {
          localStorage.setItem('applicationId', serverResponse?.aId || '');
          localStorage.setItem('referenceId', serverResponse?.refId || '');
          localStorage.setItem(
            'contactlessStatus',
            serverResponse?.contactlessStatus || ''
          );
          this.loggedUserId = serverResponse.userId;
          localStorage.setItem(
            'USD_PASSPORT_LOGGED_USER_ID',
            this.utils.encryptString(this.loggedUserId || '')
          );
          this.paymentStatus = serverResponse?.paymentStatus;

          this.esbResponseData = serverResponse;

          if (this.status == 0) {
            if (serverResponse.applicantId !== this.constants.NULL) {
              //TODO collect only booklet fee
              this.gui.openDashboardPage();
            } else {
              this.getApplicationFromApp();
            }
          }
          if (this.status == 1) {
            if (serverResponse.applicantId !== this.constants.NULL) {
              this.gui.openApplicationViewV2(serverResponse.applicantId);
            } else {
              this.getApplicationFromApp();
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching application from ESB: ', error);
        this.toast.showErrorToast(error.responseMsg);
      })
      .finally(() => {
        // this.getApplicationFromApp();
        // this.loaderService.stopLoader();
      });
  }

  private openDashboard(gui: GUI): void {
    gui.openDashboardPage();
  }

  // private processApplication(): void {
  //   if (this.status == 0) {
  //     // failed eligibility but paid
  //     //Display message: You are not eligible for contactless enrollment nad have paid for booklet fee proceed to book appointment (if you have not done so) and visit your choosen passport procesing center for biometric capture
  //     if (serverResponse?.data?.status === 'Paid') {
  //       this.gui.openShowResponse();
  //     }
  //     // failed eligibility but not paid
  //     // TODO update the application details by removing contactless status and fee then proceed to payment
  //     if (serverResponse?.data?.status !== 'Paid') {
  //       // this.initiatePayment();

  //       this.startPayment(
  //         serverResponse?.data?.bookletFee,
  //         serverResponse?.data?.fullName
  //       );
  //     }
  //   }

  //   if (this.status == 1) {
  //     // passed eligibility and has paid
  //     if (this.paymentStatus == 1) {
  //       this.toast.showErrorToastWithCallbackFunction(
  //         'You have paid for contactless passport fee already',
  //         this.openDashboard.bind(null, this.gui)
  //       );
  //     } else {
  //       this.startPayment(
  //         environment.raveContactlessFee,
  //         serverResponse?.data?.fullName
  //       );
  //     }
  //     // passed eligibility and has not paid
  //     if (serverResponse.data.status !== 'Paid') {
  //       // application should not reach this block. It should be handled in checkApplicationFromEsb success response
  //       // this.initiatePayment();
  //     }
  //   }
  // }

  private getApplicationFromApp() {
    // this.loaderService.startLoader();
    this.httpUtils
      .fetchV1ApplicationViewData(this.applicationId, this.referenceNumber)
      .then((serverResponse) => {
        if (serverResponse?.responseCode == this.constants.STATUS_SUCCESS) {
          if (this.status == 0) {
            // failed eligibility but paid
            //Display message: You are not eligible for contactless enrollment nad have paid for booklet fee proceed to book appointment (if you have not done so) and visit your choosen passport procesing center for biometric capture
            if (serverResponse?.data?.status === 'Paid') {
              this.gui.openShowResponse();
            }
            // failed eligibility but not paid
            // TODO update the application details by removing contactless status and fee then proceed to payment
            if (serverResponse?.data?.status !== 'Paid') {
              // this.initiatePayment();

              this.startPayment(
                serverResponse?.data?.bookletFee,
                serverResponse?.data?.fullName
              );
            }
          }

          if (this.status == 1) {
            // passed eligibility and has paid
            if (this.paymentStatus == 1) {
              this.toast.showErrorToastWithCallbackFunction(
                'You have paid for contactless passport fee already',
                this.openDashboard.bind(null, this.gui)
              );
            } else {
              this.startPayment(
                environment.raveContactlessFee,
                serverResponse?.data?.fullName
              );
            }
            // passed eligibility and has not paid
            if (serverResponse.data.status !== 'Paid') {
              // application should not reach this block. It should be handled in checkApplicationFromEsb success response
              // this.initiatePayment();
            }
          }
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg);
        }
      });
  }

  private esbNotifyEligbility(): void {
    // this.loaderService.startLoader();
    this.httpUtils.EsbNotifyEligibility(
      this.applicationId,
      this.referenceNumber,
      this.status
    );
    // this.loaderService.stopLoader();
  }

  private startBookletFeePayment(
    applicationId: Number,
    referenceId: Number
  ): void {
    const payload = {
      applicationId: this.referenceNumber,
      referenceId: this.applicationId,
    };
    try {
      this.httpUtils
        .startRaveBookletFee(payload)
        .then((serverResponse) => {
          if (serverResponse?.data?.redirect_url) {
            window.location.href = serverResponse?.data?.redirect_url;
          }
        })
        .catch((error) => {
          console.error('Error while starting payment:', error);
        });
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }

  private startPayment(amount: Number, fullName: string): void {
    const data = `${environment.raveMerchant}${amount.toFixed(2)}${
      this.applicationId
    }`;
    const hash = CryptoJS.HmacSHA1(data, environment.raveKey).toString();
    const payload = {
      serviceId: environment.raveService,
      txnRefNumber: this.applicationId,
      amount: amount.toFixed(2),
      countryId: 'CA', //TODO chnage country to read from application data
      hash: hash,
      miscData: `${this.referenceNumber} | ${this.applicationId} | ${fullName}`,
      successUrl: `${environment.keycloakLogoutRedirectUrl}rave-payment/${this.applicationId}/${this.referenceNumber}`,
      cancelUrl: `${environment.keycloakLogoutRedirectUrl}rave-payment-fail/${this.applicationId}/${this.referenceNumber}`,
    };
    try {
      this.httpUtils
        .startRavePayment(payload)
        .then((serverResponse) => {
          if (serverResponse?.data?.redirect_url) {
            window.location.href = serverResponse?.data?.redirect_url;
          }
        })
        .catch((error) => {
          console.error('Error while starting payment:', error);
        });
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }

  private initiatePayment() {
    const applicationsList = [
      JSON.parse(localStorage.getItem('applicationId')),
    ];
    this.httpUtils
      .initiatePayment(this.localStorage.getLoggedUserId(), applicationsList)
      .then((serverResponse) => {
        if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
          this.openIOSPaymentPage();
        } else {
          this.loaderService.stopLoader();
          this.toast.showErrorToast(serverResponse.responseMsg);
        }
      });
  }

  private openIOSPaymentPage() {
    this.applicationViewForm.action =
      environment.serverBaseUrl + 'v1/payments/openIOSPaymentPage';
    this.applicationViewForm.loggedUserId.value = this.loggedUserId;
    this.applicationViewForm.submit();
  }
}
