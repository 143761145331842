<app-header class="view-header"></app-header>

<div class="wrapper">

    <section class="main">

        <form action="" method="post" name="applicationViewForm" autocomplete="off">

            <input type="hidden" name="loggedUserId" value="" />

            <div class="profile-view">

                <div class="h_logo text-center">
                    <img src="assets/images/logo-nis.png" style="width:140px;text-align:center;">
                </div>

                <div *ngIf="personalDetailsObject.approvalStatus === constants.STATUS_QUERY" class="hide">
                    <h2 style="color: red;">YOUR APPLICATION HAS BEEN QUERIED. TO RE-UPLOAD QUERIED DOCUMENT(S), PLEASE
                        CLICK ON THIS <a [href]="personalDetailsObject.reuploadLink"
                            style="color: #0087ff; text-decoration: underline;">LINK</a>.</h2>
                </div>

                <div class="s-head">

                    <div class="result-header">

                        <div class="user_pic" *ngIf="personalDetailsObject.isV2Application === constants.TRUE"
                            style="padding: 2px;">
                            <img [src]="uploadedPhotoDetailsObject.validatedBase64Data" alt="">
                        </div>

                        <div class="user-info-detail">

                            <div class="upper_bar" style="border-bottom:1px solid #979797;">

                                <div class="bar_code">
                                    <div *ngIf="personalDetailsObject.paymentStatus === constants.STATUS_SUCCESS"
                                        class="barcode">
                                        <ngx-barcode [bc-value]="barCodeValue" [bc-display-value]="true" [bc-width]="1"
                                            [bc-height]="40" [bc-font-size]="14"></ngx-barcode>
                                    </div>
                                </div>

                                <div class="visa_type" style="text-align: right;">
                                    <p>{{passportTypeDetailsObject.passportTypeLabel}} Application</p>
                                </div>

                            </div>

                            <div class="common_view">

                                <ng-container
                                    *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_INITIATE">
                                    <div class="view-grid common_view_item">
                                        <label>Applicant Name</label>
                                        <p class="p-body">{{personalDetailsObject.fullName}}</p>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_SUCCESS">
                                    <div class="view-grid common_view_item">
                                        <label>Application No.</label>
                                        <p class="p-body">{{personalDetailsObject.applicationIdV1}}</p>
                                    </div>

                                    <div class="view-grid common_view_item">
                                        <label>Reference No.</label>
                                        <p class="p-body">{{personalDetailsObject.referenceIdV1}}</p>
                                    </div>
                                </ng-container>

                                <div class="view-grid common_view_item">
                                    <label>Application Date</label>
                                    <p class="p-body">{{personalDetailsObject.applicationDate}}</p>
                                </div>

                                <div *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_SUCCESS"
                                    class="view-grid common_view_item">
                                    <label>Application Expiry Date</label>
                                    <p class="p-body">{{personalDetailsObject.applicationExpiryDate}}</p>
                                </div>

                                <div class="view-grid common_view_item">
                                    <label>Payment Status</label>
                                    <p class="p-body"><img [src]="personalDetailsObject.paymentStatusImageURL"
                                            class="status_img" style="width: 100%; max-width: 51px;"></p>
                                </div>

                                <ng-container
                                    *ngIf="personalDetailsObject.isV2Application === constants.TRUE && personalDetailsObject.paymentStatus === 200">
                                    <div class="common_view_item">
                                        <label>Approval Status</label>
                                        <p [innerHTML]="personalDetailsObject.approvalStatusLabel"></p>
                                    </div>
                                </ng-container>

                            </div>

                        </div>

                    </div>

                </div>

                <section id="applicationdetail">

                    <div class="s-content">

                        <div class="border_line">

                            <p class="p-body line">Passport Details</p>

                            <div class="sr-p-info">

                                <div>
                                    <label>Applying For</label>
                                    <p>{{passportTypeDetailsObject.applyingForLabel}}
                                        {{passportTypeDetailsObject.passportTypeLabel}}</p>
                                </div>

                                <ng-container *ngIf="personalDetailsObject.isFreshApplication === false">
                                    <div>
                                        <label>Change Type</label>
                                        <p>{{passportTypeDetailsObject.changeTypeLabel}}</p>
                                    </div>

                                    <div>
                                        <label>Passport Number</label>
                                        <p>{{passportTypeDetailsObject.passportNumber}}</p>
                                    </div>
                                </ng-container>

                                <div>
                                    <label>Age Group</label>
                                    <p>{{passportTypeDetailsObject.ageGroupLabel}}</p>
                                </div>

                                <div>
                                    <label>Processing Country / Processing Embassy</label>
                                    <p>{{passportTypeDetailsObject.processingCountryLabel}} /
                                        {{passportTypeDetailsObject.processingEmbassyLabel}}</p>
                                </div>

                                <div>
                                    <label>Booklet Type / Validity</label>
                                    <p>{{passportTypeDetailsObject.bookletTypelabel}} /
                                        {{passportTypeDetailsObject.validityLabel}}</p>
                                </div>

                            </div>

                        </div>

                        <div class="border_line">

                            <p class="p-body line">Personal Details</p>

                            <div class="sr-p-info">

                                <div>
                                    <label>Full Name</label>
                                    <p>{{personalDetailsObject.fullName}}</p>
                                </div>

                                <div>
                                    <label>Date of Birth (DD/MM/YYYY)</label>
                                    <p>{{personalDetailsObject.dateOfBirth}}</p>
                                </div>

                                <div>
                                    <label>Place of Birth</label>
                                    <p>{{personalDetailsObject.placeOfBirth}}</p>
                                </div>

                                <div>
                                    <label>Country of Birth</label>
                                    <p>{{personalDetailsObject.countryOfBirth}}</p>
                                </div>

                                <div>
                                    <label>Gender</label>
                                    <p>{{personalDetailsObject.genderLabel}}</p>
                                </div>

                                <div>
                                    <label>Marital Status</label>
                                    <p>{{personalDetailsObject.maritalStatusLabel}}</p>
                                </div>

                                <div>
                                    <label>Occupation</label>
                                    <p>{{personalDetailsObject.occupationLabel}}</p>
                                </div>

                                <div>
                                    <label>Maiden Name</label>
                                    <p>{{personalDetailsObject.maidenName}}</p>
                                </div>

                                <div>
                                    <label>National Identification Number (NIN)</label>
                                    <p>{{personalDetailsObject.ninNumberLabel}}</p>
                                </div>

                                <div>
                                    <label>Communication Email</label>
                                    <p>{{personalDetailsObject.emailId}}</p>
                                </div>

                            </div>

                        </div>

                        <div class="border_line">

                            <p class="p-body line">Contact Information</p>

                            <div class="sr-p-info">

                                <div>
                                    <label>Contact Number</label>
                                    <p>{{contactDetailsObject.mobileNumberLabel}}</p>
                                </div>

                                <div>
                                    <label>Additional Number</label>
                                    <p>{{contactDetailsObject.phoneNumberLabel}}</p>
                                </div>

                                <div>
                                    <label>Current Address</label>
                                    <p>{{contactDetailsObject.currentAddressFullLabel}}</p>
                                </div>

                                <div>
                                    <label>Permanent Address</label>
                                    <p>{{contactDetailsObject.permanentAddressFullLabel}}</p>
                                </div>

                                <div>
                                    <label>State of Origin</label>
                                    <p>{{contactDetailsObject.permanentOriginStateLabel}}</p>
                                </div>

                                <div>
                                    <label>Home Town</label>
                                    <p>{{contactDetailsObject.permanentHomeTown}}</p>
                                </div>

                            </div>

                        </div>

                        <div class="border_line">

                            <p class="p-body line pb10">Kin Information</p>

                            <div class="sr-p-info">

                                <div>
                                    <label>Name of Kin</label>
                                    <p>{{kinDetailsObject.kinName}}</p>
                                </div>

                                <div>
                                    <label>Relationship</label>
                                    <p>{{kinDetailsObject.relationshipLabel}}</p>
                                </div>

                                <div>
                                    <label>Address</label>
                                    <p>{{kinDetailsObject.permanentAddressFullLabel}}</p>
                                </div>

                                <div>
                                    <label>Contact No.</label>
                                    <p>{{kinDetailsObject.mobileNumberLabel}}</p>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="enablePassportDeliveryStage === true" class="border_line page-break">
                            <p class="p-body  line pb10">Passport Delivery Information</p>
                            <div class="sr-p-info">
                                <ng-container *ngIf="passportDeliveryDetailsObject.deliveryOpted !== constants.TRUE">
                                    <div>
                                        <label>Chosen Delivery Option</label>
                                        <p>Self pickup from the processing center</p>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="passportDeliveryDetailsObject.deliveryOpted !== constants.FALSE">
                                    <div>
                                        <label>Chosen Delivery Option</label>
                                        <p>Delivery by Courier</p>
                                    </div>

                                    <div>
                                        <label>Name of Receiver</label>
                                        <p>{{passportDeliveryDetailsObject.receiverName}}</p>
                                    </div>

                                    <div>
                                        <label>Receiver's E-mail Address</label>
                                        <p>{{passportDeliveryDetailsObject.emailId}}</p>
                                    </div>

                                    <div>
                                        <label>Receiver's Contact Number</label>
                                        <p>{{passportDeliveryDetailsObject.mobileNumberLabel}}</p>
                                    </div>

                                    <div>
                                        <label>Alternate Phone Number</label>
                                        <p>{{passportDeliveryDetailsObject.phoneNumberLabel}}</p>
                                    </div>

                                    <div>
                                        <label>Address</label>
                                        <p>{{passportDeliveryDetailsObject.receiverFullAddress}}</p>
                                    </div>
                                </ng-container>
                            </div>

                        </div>

                        <div class="border_line" *ngIf="codFieldsList.length > 0">

                            <div class="cod-change-head">
                                <p class="p-body line">Fields to be Changed</p>
                                <p class="p-body line">Current Data</p>
                                <p class="p-body line">New Data</p>
                            </div>

                            <table class="cod-change-data">
                                <tbody>
                                    <tr *ngFor="let codObject of codFieldsList">
                                        <td>{{codObject.fieldLabel}}</td>
                                        <td>{{codObject.dataOnPassport}}</td>
                                        <td>{{codObject.dataOnNIN}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <ng-container
                            *ngIf="personalDetailsObject.isV2Application === constants.FALSE && isShowPaymentDetails === true">
                            <div class="half_col pay_details">
                                <div class="half_col_box" style="margin-right:10px;">
                                    <ng-template [ngTemplateOutlet]="feeDetails"></ng-template>
                                </div>

                                <div *ngIf="personalDetailsObject.paymentStatus === constants.STATUS_SUCCESS"
                                    class="half_col_box">
                                    <ng-template [ngTemplateOutlet]="paymentDetails"></ng-template>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="personalDetailsObject.isV2Application === constants.TRUE">

                            <table class="profile_table standard-prof-table mt10">

                                <tr>

                                    <td class="tab-print">

                                        <div *ngIf="applicableDocsList.length > constants.FALSE"
                                            class="half_col_box_doc">

                                            <p class="p-body line pb10">Supporting Documents</p>

                                            <div class="tab50">

                                                <div class="tab50-inner"
                                                    *ngFor="let applicableDocSectionObject of applicableDocsList;">

                                                    <p> {{applicableDocSectionObject.groupTypeLabel}}</p>

                                                    <ng-container
                                                        *ngFor="let applicableDocObject of applicableDocSectionObject.groupDocs;">
                                                        <table *ngIf="applicableDocObject.isDocUploaded === true"
                                                            class="profile_table documentt_view"
                                                            style="margin-top:0 !important;">
                                                            <tr>
                                                                <td class="prof-line" style="height:auto !important;">
                                                                    <label>{{applicableDocObject.count}}.
                                                                        {{applicableDocObject.docTypeLabel}} </label>
                                                                </td>

                                                                <td class="prof-eye" style="height:auto !important;">
                                                                    <span class="fr"><i
                                                                            (click)="openFilePreviewModal(applicableDocObject);"
                                                                            class="fa fa-eye"></i></span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </ng-container>


                                                </div>

                                            </div>

                                        </div>

                                    </td>

                                    <td class="tab-d-print">
                                        <table *ngIf="isShowPaymentDetails === true" class="profile_table pay_details"
                                            style="margin-top: 0!important;">
                                            <tr class="mt-8">
                                                <td>
                                                    <div class="half_col_box_doc">
                                                        <ng-template [ngTemplateOutlet]="feeDetails"></ng-template>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr *ngIf="isPaymentDetailsExist === true">
                                                <td>
                                                    <div class="half_col_box_doc" style="margin-top: 10px;">
                                                        <ng-template [ngTemplateOutlet]="paymentDetails"></ng-template>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </ng-container>

                        <label *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_INITIATE"
                            class="t_and_c confirmation-print-none pointer">
                            <input type="checkbox" name="termCondition" [(ngModel)]="isTermConditionSelect"
                                #termCondition="ngModel" required="required">
                            <span style="padding:0 10px;">
                                I hereby confirm that all details furnished herein are genuine, precise, and rightfully
                                associated with my identity.
                                I affirm the accuracy of this declaration to the best of my knowledge and conviction. In
                                making this solemn affirmation,
                                I do so conscientiously and sincerely, believing every piece of information provided to
                                be true,
                                in accordance with the Oaths Act, 2004, Cap 01, Laws of the Federation of Nigeria.
                                I hereby acknowledge the legal consequences of submitting inaccurate information or
                                committing perjury and I willingly accept all
                                lawful penalties applicable for any misinformation provided in this document.</span>
                            <span class="alert alert-danger" role="alert"
                                *ngIf="confirmationButtonClicked && termCondition.invalid">
                                <span *ngIf="termCondition.errors.required">
                                    Confirmation is required
                                </span>
                            </span>
                        </label>
                    </div>
                </section>

                <div class="view-buttons">
                    <input type="button" (click)="backToPage()" value="Close" class="back_btn">

                    <input *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_INITIATE" type="button"
                        (click)="editApplication();" value="Edit" class="edit_btn" [disabled]="isDisabledButton">

                    <input *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_INITIATE" type="button"
                        (click)="confirmationButtonClicked = true; showConfirmationForApplicationSubmission(constants.FALSE);"
                        value="{{ isContactlessStatus ? 'Confirm & Submit':'Confirm & Pay Later' }}" class="save_btn"
                        [disabled]="isDisabledButton">

                    <ng-container *ngIf="!isContactlessStatus">
                        <input *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_INITIATE"
                            type="button"
                            (click)="confirmationButtonClicked = true; showConfirmationForApplicationSubmission(constants.TRUE);"
                            value="Submit & Pay Now" class="custom_btn submit-pay" [disabled]="isDisabledButton">
                    </ng-container>

                    <input *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_SUCCESS" type="button"
                        (click)="printApplication();" value="Print Application" class="save_btn">
                    <!-- <input *ngIf="personalDetailsObject.applicationStatus === constants.STATUS_SUCCESS" type="button"
                        (click)="printApplication();" value="Proceed to Pay for Booklet" class="save_btn"> -->

                    <ng-container
                        *ngIf="(isContactlessStatus === true) && (isEligible === constants.TRUE)"></ng-container>
                    <input
                        *ngIf="(personalDetailsObject.applicationStatus === constants.STATUS_SUCCESS) && (isContactlessStatus === true) && (isEligible === constants.TRUE) "
                        type="button" (click)="proceedToPay()" value="Proceed to Pay Now" class="custom_btn submit-pay"
                        [disabled]="isDisabledButton">


                    <input *ngIf="personalDetailsObject.paymentStatus === constants.STATUS_SUCCESS" type="button"
                        (click)="printReceipt()" value="Print Receipt" class="custom_btn pay_btn submit-pay">

                    <input *ngIf="personalDetailsObject.paymentStatus === constants.STATUS_SUCCESS" type="button"
                        (click)="storeFeeAndPaymentDataFromIOS();"
                        [value]="isShowPaymentDetails === true ? 'Hide Payment Details' : 'Show Payment Details'"
                        class="custom_btn margin0">
                </div>
            </div>
        </form>
    </section>
</div>

<app-footer class="view-footer"></app-footer>

<ng-template #feeDetails>
    <p class="p-body line">Fee Details</p>
    <div class="tab50">
        <ng-container *ngIf="personalDetailsObject.paymentStatus !== constants.STATUS_SUCCESS">
            <div *ngIf="feeDetailsObject.codFee > 0">
                <label>COD Fee</label>
                <span class="fr">{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.codFeeView | number : '1.2' :
                    'en-US'}}</span>
            </div>

            <div>
                <label>Passport Booklet Fee</label>
                <span class="fr">{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.bookletFee | number : '1.2' :
                    'en-US'}}</span>
            </div>

            <div *ngIf="feeDetailsObject.deliveryFee > 0">
                <label>Passport Delivery Fee</label>
                <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.deliveryFee | number : '1.2' :
                    'en-US'}}</span>
            </div>

            <div *ngIf="(isContactlessStatus === true) && (isEligible !== constants.FALSE)">
                <label>Contactless Fee</label>
                <span class="fr">{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.contactlessFee | number : '1.2' :
                    'en-US'}}</span>
            </div>

        </ng-container>

        <ng-container *ngIf="personalDetailsObject.paymentStatus === constants.STATUS_SUCCESS">
            <div *ngIf="paymentDetailsObject.codFeeByGateway > 0">
                <label>COD Fee</label>
                <span class="fr">{{paymentDetailsObject.currencySymbolByGatway}}{{paymentDetailsObject.codFeeByGateway |
                    number : '1.2' : 'en-US'}}</span>
            </div>

            <div>
                <label>Passport Booklet Fee</label>
                <span
                    class="fr">{{paymentDetailsObject.currencySymbolByGatway}}{{paymentDetailsObject.bookletFeeByGatway
                    | number : '1.2' : 'en-US'}}
                    <span
                        *ngIf="paymentDetailsObject.currencyCode !== paymentDetailsObject.currencyCodeByGatway">({{paymentDetailsObject.currencySymbol}}{{paymentDetailsObject.bookletFee
                        | number : '1.2' : 'en-US'}})</span></span>
            </div>

            <div *ngIf="paymentDetailsObject.deliveryFeeByGateway > 0">
                <label>Passport Delivery Fee</label>
                <span
                    class="fr">{{paymentDetailsObject.currencySymbolByGatway}}{{paymentDetailsObject.deliveryFeeByGateway
                    | number : '1.2' : 'en-US'}}</span>
            </div>

            <div>
                <label>Transaction Fee</label>
                <span class="fr">{{paymentDetailsObject.currencySymbolByGatway}}{{paymentDetailsObject.gatewayTxnFee |
                    number : '1.2' : 'en-US'}}</span>
            </div>

            <div *ngIf="(isContactlessStatus === true) && (isEligible !== constants.FALSE)">
                <label>Contactless Fee:</label>
                <span class="fr">{{ paymentDetailsObject.currencySymbol }} {{ feeDetailsObject.contactlessFee | number :
                    "1.2" : "en-US" }}</span>
            </div>

            <div class="total_box">
                <label>Total Fee</label>
                <span
                    class="fr">{{paymentDetailsObject.currencySymbolByGatway}}{{paymentDetailsObject.totalAmountCollectedByGatway
                    + (isContactlessStatus ? 100 : 0) | number : '1.2' : 'en-US'}}</span>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #paymentDetails>
    <p class="p-body line">Payment Details</p>
    <div class="tab50">
        <div>
            <label>Payment Date</label>
            <span class="fr">{{paymentDetailsObject.paymentDate}}</span>
        </div>

        <div>
            <label>Payment Gateway</label>
            <span class="fr">{{paymentDetailsObject.paymentGateway}}</span>
        </div>

        <div>
            <label>Validation Number</label>
            <span class="fr">{{paymentDetailsObject.validationNo}}</span>
        </div>
    </div>
</ng-template>