import { Component, NgZone, OnInit } from '@angular/core';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Utils } from '../common/utils';
import { Route } from '../common/route';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { HttpUtils } from '../common/http-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rave-payment-response',
  templateUrl: './rave-payment.component.html',
  styleUrls: ['./rave-payment.component.css'],
})
export class RavePaymentComponent implements OnInit {
  transactionId: string = this.constants.BLANK;
  applicationId: string | null = null;
  referenceNumber: string | null = null;
  isLoading = true;

  paymentDetailsObject: any = {};
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private httpUtils: HttpUtils,
    private toast: Toast,
    public constants: Constants,
    private utils: Utils,
    private loaderService: LoaderService,
    private route: Route
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.applicationId = params['applicationId'] || null;
      this.referenceNumber = params['referenceNumber'] || null;
    });
    // this.checkPaymentStatus();
  }

  ngOnInit(): void {
    this.esbNotifyPayment();
  }

  // private checkPaymentStatus() {
  //   this.loaderService.startLoader();
  //   this.httpUtils.confirmRavePayment(this.transactionId).then(serverResponse => {
  //     this.loaderService.stopLoader();
  //     if(serverResponse){
  //       console.log("payment response", serverResponse);
  //     } else {
  //       this.toast.showErrorToast(serverResponse.responseMsg);
  //     }
  //   });
  // }

  private esbNotifyPayment(): void {
    this.loaderService.startLoader();
    this.httpUtils.EsbNotifyPayment(
      this.applicationId,
      this.referenceNumber,
      1
    );
    this.loaderService.stopLoader();
  }

  openHomePage() {
    this.ngZone.run(() => this.router.navigate([this.route.HOME]));
  }
}
