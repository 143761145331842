export const environment = {
  production: false,

  buildDate: '30/07/2024',

  authorizationKey: 'GoyBKWuIr8aqKKThRjA5',

  // serverBaseUrl: 'http://localhost:8080/app/',

  serverBaseUrl: 'https://passportintl.swglobalstaging.com/app/',
  // serverBaseUrl: 'https://staging.newworkssolution.com:8443/usPassportBackend/',
  // serverBaseUrl: 'https://passportintl.immigration.gov.ng/app/',

  esbUrl: 'https://esb.swglobalstaging.com/esb/api/',

  appointmentApplicationUrl: 'https://apt-stage.newworkssolution.com/',

  checkCentreAvailabilityUrl:
    'https://apt-stage.newworkssolution.com/availability/',

  trackApplicationUrl: 'https://track.immigration.gov.ng',

  passportApplicationRequirementsUrl: 'https://immigration.gov.ng/learn-more/',

  ngnPassportApplicationUrl: 'https://staging.newworkssolution.com/',

  // keycloakUrl: 'https://auth.swglobalstaging.com/auth/',

  // keycloakRealm: 'immigration',

  // keycloakClientId: 'immigration_auth',

  keycloakRedirectUrl: 'https://passportintl.swglobalstaging.com/login/',

  keycloakLogoutRedirectUrl: 'https://passportintl.swglobalstaging.com/',

  domain: 'passportintl.swglobalstaging.com',

  // path: '/',

  keycloakUrl: 'https://auth.swglobalstaging.com/auth/',

  keycloakRealm: 'immigration',

  keycloakClientId: 'immigration_auth',

  // keycloakRedirectUrl: 'http://localhost:4200/login/',

  // keycloakLogoutRedirectUrl: 'http://localhost:4200/',

  // domain: 'localhost:4200',

  path: '/',

  enablePassportDeliveryStage: false,

  passportDeliveryTermsLink: 'https://bit.ly/gwx-terms-and-conditions',

  raveMerchant: 'NIS_CONTACTLESS_FEE',

  raveService: 'NIS_CONTACTLESS_FEE',

  raveKey: '%fKcJnr#NT%Z38',

  raveUrl: 'https://rave.swglobalstaging.com',

  raveContactlessFee: 100,
};
